import store from '@/store'
import router from '@/router'
import { ref, getCurrentInstance } from '@vue/composition-api'
import { formatTasks, formatOptions } from '@core/utils'
import { foundryRequest } from '@/config/authConfig';

export default function useVizView() {
  
    const vm = getCurrentInstance().proxy
    
    const vizData = ref('')
    const vizLoaded = ref(false)
    const fieldDashboardData = ref('')
    const fieldDashboardLoaded = ref(false)

    
    const checkDecipherVariables = async (queryParams) => {
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
          .dispatch('app-forsta/checkDecipherVariables', queryParams)
          .then(response => {
            const { data } = response
            if (data.length){
                vizData.value = data[0].result
                // remove loading state
            } else {
                vizData.value = 'PROJECT NOT FOUND!'
            }
            vizLoaded.value = true
          })
          .catch(error => {
            console.log(error)
          })
    }

    const generateFieldDashboard = async (queryParams) => {
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      
      store
        .dispatch('app-forsta/generateFieldDashboard', queryParams)
        .then(response => {
          const { data } = response
          if (data.length){
              fieldDashboardData.value = data[0].result
              // remove loading state
          } else {
              fieldDashboardData.value = 'PROJECT NOT FOUND!'
          }
          fieldDashboardLoaded.value = true
        })
        .catch(error => {
          console.log(error)
        })
  }

    return {
        vizLoaded,
        vizData,
        fieldDashboardData,
        fieldDashboardLoaded,

        checkDecipherVariables,
        generateFieldDashboard
    }
}