import axios from '@axios'
import store from '@/store'
import { transformRequestOptions } from '@core/utils'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    checkDecipherVariables(ctx, queryParams) {
      store.commit('app/LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .get(`/forsta/check/`, {
            params: queryParams,
            paramsSerializer: params => transformRequestOptions(params),
            useCache: true
          })
          .then(response => {
            store.commit('app/LOADING', false)
            resolve(response)
          })
          .catch(error => {
            store.commit('app/LOADING', false)
            reject(error)})
      })
    },
    generateFieldDashboard(ctx, queryParams) {
      store.commit('app/LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .get(`/forsta/field_dashboard/`, {
            params: queryParams,
            paramsSerializer: params => transformRequestOptions(params),
            useCache: true
          })
          .then(response => {
            store.commit('app/LOADING', false)
            resolve(response)
          })
          .catch(error => {
            store.commit('app/LOADING', false)
            reject(error)})
      })
    },
  },
}
