<template>
    <v-col cols="12"
    sm="12" md="12">
        <v-row class="pb-4">

            <app-card-code
                title="Forsta Viz Var Check"
                :code="vizData"
                :isCodeCollapsed="vizLoaded"
                 width="100%"
                >
                
                <v-card-text>
                    <v-col cols="12"
                        sm="12"
                        md="12">
                        <v-text-field
                            v-model="decipherPath"
                            label="Decipher link"
                            placeholder="gb001"
                            outlined
                            required
                            dense
                            hide-details="auto"
                            
                            :append-icon="icons.mdiSendOutline"
                            @keydown.enter.exact="checkVar"
                        >
                            
                        </v-text-field>
                    </v-col>
                </v-card-text>
            </app-card-code>

        </v-row>
        <v-row>

        <app-card-code
            title="Forsta Field Dashboard"
            :code="fieldDashboardData"
            :isCodeCollapsed="fieldDashboardLoaded" width="100%"
            >
            
            <v-card-text>
                <v-col cols="12"
                    sm="12"
                    md="12">
                    <v-text-field
                        v-model="decipherPath"
                        label="Decipher link"
                        placeholder="gb001"
                        outlined
                        required
                        dense
                        hide-details="auto"
                        
                        :append-icon="icons.mdiSendOutline"
                        @keydown.enter.exact="fieldDashboard"
                    >
                        
                    </v-text-field>
                </v-col>
            </v-card-text>
        </app-card-code>

        </v-row>
    </v-col>
    
</template>

<script>
import { onUnmounted, ref, onMounted } from '@vue/composition-api'
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiSendOutline,
} from '@mdi/js'
import useForstaView from './useForstaView'
import forstaStoreModule from '@/views/apps/forsta/forstaStoreModule'
import store from '@/store'

export default ({
    components: {
        AppCardCode
    },
    setup() {

        const codeSnippet = ref('')
        const decipherPath = ref('')

        
        const {
            vizData,
            vizLoaded,
            checkDecipherVariables,
            fieldDashboardData,
            fieldDashboardLoaded,
            generateFieldDashboard
        } = useForstaView()

        
        const FORSTA_APP_STORE_MODULE_NAME = 'app-forsta'

        // Register module
        if (!store.hasModule(FORSTA_APP_STORE_MODULE_NAME)) store.registerModule(FORSTA_APP_STORE_MODULE_NAME, forstaStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(FORSTA_APP_STORE_MODULE_NAME)) store.unregisterModule(FORSTA_APP_STORE_MODULE_NAME)
        })


        const checkVar = () => {
            checkDecipherVariables({project:decipherPath.value})
        }

        
        const fieldDashboard = () => {
            generateFieldDashboard({project:decipherPath.value})
        }

        return {
            codeSnippet,
            decipherPath,
            vizData,
            vizLoaded,
            checkVar,
            fieldDashboardData,
            fieldDashboardLoaded,
            fieldDashboard,
            icons: {
                mdiSendOutline
            }
        }
        
    },
})
</script>
